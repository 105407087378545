// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
.Fade {

  animation: FadeAnim;

  animation-duration: 2s;

  animation-delay: 0.1s;

  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  animation-fill-mode: forwards;

  opacity: 0;

}
@keyframes FadeAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.invalid-feedback{display: block;}
.details-shipment-group .cards-detail .h1{font-size: 24px;}
.details-shipment-group .labels-input-shipments {padding:20px;margin-bottom: 20px;}
.details-shipment-group .labels-input-shipments label {margin:0px;align-items: center;height: 100%;display: flex;}

@keyframes modalFade {
  from {transform: translateX(50%);opacity: 0;}

  to {transform: translateX(0);opacity: 1;}
}
.modal-dialog.modal-lg.next {
  animation-name: modalFade;
  animation-duration: .1s;
}
@keyframes modalFadeBackGround {
  from {transform: translateX(-50%);opacity: 0;box-shadow: 1px 0px 16px 2px red;background: rgb(2,0,36);border-radius: 100px;
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(245,59,59,0.6418942577030813) 0%, rgba(255,0,0,0.28335084033613445) 55%);}
  to {transform: translateX(0);opacity: 1;box-shadow: none;background: none;border: none;}
}
.animation-change-background {
  animation-name: modalFadeBackGround;
  animation-duration: 0.9s;
}

.stock-add-block {display: flex;justify-content: space-between;}
.stock-add-block .barcode-input{width: 300px;justify-content: flex-start;display: flex;}
.stock-add-block .barcode-input > input {width: 80%}
.stock-add-block .qty-input{width: 80px;}
.sku-info {padding:5px 10px;margin:0px;}
.qty-input-list {width: 100px;}
.table-stock .badge {width: 100%;}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1024px;
  }
}
.text-bold {font-weight: bold;}
.modal-separation-btns {justify-content: flex-end;display: flex;}
.modal-separation-btns button {margin-left:10px;}
#selectOrder {height: 90px;padding:0px;}
#selectOrder option{padding:3px 5px;}
#selectOrder option:checked { background-color: #fff!important;}
select[multiple]:focus option:checked {
  background: #20a8d8 linear-gradient(0deg, #20a8d8 0%, #20a8d8 100%);
}
select[multiple] option:checked {
  background: #20a8d8 linear-gradient(0deg, #20a8d8 0%, #20a8d8 100%);
}
select[multiple]:focus option.status-1:checked {
  background: #4ab670 linear-gradient(0deg, #4ab670 0%, #4ab670 100%);
}
select[multiple] option.status-1:checked {
  background: #4ab670 linear-gradient(0deg, #4ab670 0%, #4ab670 100%);
}
.modal-separating .modal-body {min-height: 350px;}


.table-size-btn .btn {
  font-size: 0.66562rem;
}
.table-size-btn td, .table-size-btn th {
  font-size: 0.79rem;
}
.table.no-border-top th{
  border-top:none;
}
.btn-floater-danger {
  position: fixed;
  width: 66px;
  height: 66px;
  bottom: 40px;
  right: 120px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
}
.btn-floater {
  position: fixed;
  width: 66px;
  height: 66px;
  bottom: 40px;
  right: 40px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
}
.fixed-size-table {
  min-height: 603px;
}
.select-order {
  display: flex;
  flex-direction: row;
}
.select-order > ul.list {
  width: 40%;
}
.select-order .info-orders {
  width: 30%;
  padding-left:15px;
}
.select-order .info-orders p {
  margin-bottom: 5px;
}
.card-size-orders-info {
  height: 123px;
}
.shipments-info .card-size-orders-info {
  height: 144px;
}
ul.list {
  overflow: auto;
  height: 200px;
  list-style: none;
  border:1px solid #d7d7d7;
  border-radius: 5px;
  padding:0px;
}
ul.list li {
  list-style: none;
  padding:0px;
}
ul.list li span {
  padding:3px 0px 3px 8px;
  cursor: pointer;
  width: 100%;
  display: block;
}
.status-1{color: #fff;background-color: #4dbd74;}
ul.list li.active {background-color: #20a8d8;color:#fff;}
ul.list li.active.status-1{color: #fff;background-color: #4dbd74;}


.infos-order-separation {
  position: relative;
  top: -40px;
}
.row-reverse {flex-direction: row-reverse;}
.text-right {
  text-align: right;
}
.invoice-td {width: 190px;word-break: break-word;}
.react-checkbox-tree .rct-node-icon { color:#000; }
.modal-title {width: 100%}
.modal-title .header-span {margin-left:15px;}
.alert-orange{background-color: #f8e390}
.alert-danger.alert-orange {background-color:#fee2e1}
.show-inline {display: flex;justify-content: space-between}
.show-inline button{padding:0px 4px;}

/*datapicker ovverride*/
.DateRangePicker .DateInput_input{font-size: 0.875rem;padding: 4px 10px;border-radius: 5px;}
.DateRangePicker .DateInput{border-radius: 5px;}
.DateRangePicker .DateRangePickerInput__withBorder{border-radius: 5px;border:1px solid #e4e7ea;}
.DateRangePicker .DateInput_input__focused{border-radius: 5px 5px 0px 0px;}
.DateRangePicker .DateInput {width: 107px;}
.message{padding:10px;}
.unread {background-color: #f0f3f5;}

.text-black{color:#23282c;}

.m-l-4 {
  margin-left:4px;
}
